.key{
  position: relative;
  height: 420px;
  background-color: #ececec;
  border-bottom: solid 2px #3450a4;
  .inner{
    max-width: 1280px;
    padding: 0 2rem;
    margin: 0 auto;
    height: 100%;
  }
  .box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    .catch{
      &>span{
        position: relative;
        display: block;
        font-size: 36px;
        font-weight: 300;
        line-height: 1.0;
        letter-spacing: 0.2em;
        span{
          position: relative;
          z-index: 1;
        }
        &::before{
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          z-index: 0;
          display: block;
          width: calc(100% - 0.2em);
          height: 0.4em;
          background-color: #fff;
        }
        &+*{
          margin-top: 0.7em;
        }
      }
    }
  }
  &::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    background-color: #ccc;
    background-image: url("/images/key01.webp");
    background-size: cover;
    background-position: center;
  }
}